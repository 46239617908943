import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StyledNavLink, StyledListItemLiveAudit, AutoResizeTextarea } from "./styledComponents.js";
import { ThemeContext } from "./theme.js";
import { ThemeProvider } from "styled-components";
import Swal from "sweetalert2";
import { BounceLoader } from "react-spinners";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faFileUpload,
  faComment,
  faCircleCheck,
  faExclamationTriangle,
  faTrophy,
  faVideo,
  faFilePdf,
  faFileWord,
  faCirclePlus,
  faPlus,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "./CircularProgress.js";

const apiUrl = process.env.REACT_APP_API_URL;

const AuditContentLive = () => {
  const { auditId } = useParams();
  const [auditContent, setAuditContent] = useState(null);
  const [organizedData, setOrganizedData] = useState({}); // State to store organized data
  const { theme, setTheme } = useContext(ThemeContext);
  const [activeTopicIndex, setActiveTopicIndex] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isRegulationLoading, setIsRegulationLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [activeTopicIndices, setActiveTopicIndices] = useState({});
  const fileInputRef = useRef(null);
  const [comments, setComments] = useState({}); // State for storing comments
  const [showCommentBox, setShowCommentBox] = useState({});
  const [topicProgress, setTopicProgress] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);
  const [dropTarget, setDropTarget] = useState(null);

  const isTopicUnsafe = (topicQuestions) => {
    return topicQuestions.some(
      (q) => selectedAnswers[q.questionID] === "Unsafe"
    );
  };

  const isTopicExceed = (topicQuestions) => {
    return topicQuestions.some(
      (q) => selectedAnswers[q.questionID] === "Exceeding"
    );
  };

  const handleDragStart = (e, item) => {
    const { checklistIndex, topicIndex, questionID } = item;
    console.log("Audit Content", auditContent);
    // Find the item in auditContent that matches the questionID
    const checklist = auditContent.find(item => item.audit_question_id === questionID);

    if (!checklist) {
        console.error('Question not found in auditContent');
        return;
    }

    // Assuming each checklist object contains the required properties
    const checklistId = checklist.checklist_header_id;
    const checklistDetailId = checklist.checklist_detail_id;
    const questionIndex = checklist.q_list_order;

    // Now you can set the dragged item with the checklist and question IDs
    setDraggedItem({ checklistIndex, topicIndex, checklistId, checklistDetailId, questionIndex, questionID });
    console.log("Drag start", checklistIndex, topicIndex, checklistId, checklistDetailId, questionIndex, questionID);
};

  const handleDragEnter = (e, item) => {
  e.preventDefault();
  e.stopPropagation();
  // Set the drop target with the checklistIndex, topicIndex, and newQuestionIndex
  setDropTarget({ checklistIndex: item.checklistIndex, topicIndex: item.topicIndex, questionIndex: item.questionIndex });
 // console.log("Drag enter", item.checklistIndex, item.topicIndex, item.questionIndex);
  };

  const handleDragEnd = () => {
    // Ensure that draggedItem and dropTarget are defined
    if (!draggedItem) {
        console.error('Dragged item is not defined');
        return;
    }
    if (!dropTarget) {
        console.error('Drop target is not defined');
        return;
    }

    // Get the dropped element's information from dropTarget
    const { checklistIndex, topicIndex, questionIndex } = dropTarget;

    // Ensure the checklist index remains the same as the start drag checklist index
    const newChecklistIndex = draggedItem.checklistIndex;
    const newQuestionIndex = questionIndex; // Use the questionIndex from dropTarget

    // Perform any necessary state updates or actions here

    console.log("Checklist Index:", newChecklistIndex, "Old Checklist Index:", draggedItem.checklistIndex);
    console.log("New Topic Index:", topicIndex, "Old Topic Index:", draggedItem.topicIndex);
    console.log("New Question Index:", newQuestionIndex, "Old Question Index:", draggedItem.questionIndex);
    console.log("ChecklistDetailId:", draggedItem.checklistDetailId);
    
    // Check if the newTopicIndex matches the draggedItem's topic index and is in the same checklist
    if (topicIndex === draggedItem.topicIndex && newChecklistIndex === draggedItem.checklistIndex) {
      // Call the function to update the question order in the backend
      updateQuestionOrderInTopic(newQuestionIndex, draggedItem.checklistDetailId);
  }
  
    setDraggedItem(null);
    setDropTarget(null);
  };

  const fetchAuditContent = async () => {
  try {
    const response = await axios.get(
      `${apiUrl}/audit/get_audit_content/${auditId}`
    );
    setAuditContent(response.data);
    console.log("Audit Content", response.data);
  } catch (error) {
    console.error("Error fetching audit content", error);
  }
  };

useEffect(() => {
  if (auditId) {
    fetchAuditContent();
  }
}, [auditId]);

  const updateQuestionOrderInTopic = (newQuestionIndex, checklistDetailId) => {
  // Send a request to the backend to update the question order within the topic
  console.log("Updating question order within topic:", newQuestionIndex, checklistDetailId);
  axios
    .put(`${apiUrl}/audit/reorder_by_id/questions`, {
      id: checklistDetailId,
      q_list_order: newQuestionIndex
    })
    .then((response) => {
      // Handle the response if needed
      console.log("Question order within topic updated successfully:", response.data);
      
      // Call fetchAuditContent to update the audit content after the question order is updated
      fetchAuditContent();
    })
    .catch((error) => {
      // Handle errors if the request fails
      console.error("Error updating question order within topic:", error);
    });
  };

  const fetchAttachments = async (item) => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/get_attachments/${item.id}`
      );
      return response.data.attachments;
    } catch (error) {
      console.error("Error fetching attachments", error);
      return [];
    }
  };


  useEffect(() => {
    if (auditContent) {
      const tempOrganizedData = {};
      const tempSelectedAnswers = {};
      const tempComments = {};
      const tempShowCommentBox = {};
      const newTopicProgress = {};
      
      // Initialize structure for organizing topics and questions
      auditContent.forEach((item) => {
        if (!tempOrganizedData[item.checklist_name]) {
          tempOrganizedData[item.checklist_name] = {};
        }
        if (!tempOrganizedData[item.checklist_name][item.topic_name]) {
          tempOrganizedData[item.checklist_name][item.topic_name] = {
            questions: [],
            topic_order: item.topic_order,
          };
        }
      });
      // Process each item in auditContent
      Promise.all(
        auditContent.map(async (item) => {
          const attachments = await fetchAttachments(item);
          const {
            id,
            audit_header_id,
            checklist_name,
            topic_name,
            question,
            regulatory_source,
            title,
            erule,
            audit_question_id,
            answer,
            comment,
          } = item;
          tempSelectedAnswers[audit_question_id] = answer;
          tempComments[id] = comment;
          tempShowCommentBox[id] = !!comment;
          // Push question data into the corresponding topic
          tempOrganizedData[checklist_name][topic_name].questions.push({
            id,
            auditHeaderId: audit_header_id,
            questionText: question,
            regulatorySource: regulatory_source,
            regulatoryTitle: title,
            erule,
            questionID: audit_question_id,
            attachments,
            q_list_order: item.q_list_order,
          });
          // Update topic progress
          const topicKey = `${checklist_name}-${topic_name}`;
          if (!newTopicProgress[topicKey]) {
            newTopicProgress[topicKey] = { total: 0, answered: 0 };
          }
          newTopicProgress[topicKey].total += 1;
          if (answer) {
            newTopicProgress[topicKey].answered += 1;
          }
          return item;
        })
      ).then(() => {
        // Sort questions within each topic and sort topics within each checklist
        Object.keys(tempOrganizedData).forEach((checklistName) => {
          Object.entries(tempOrganizedData[checklistName])
            .sort((a, b) => a[1].topic_order - b[1].topic_order)
            .forEach(([topicName, topicData]) => {
              topicData.questions.sort(
                (a, b) => a.q_list_order - b.q_list_order
              );
              tempOrganizedData[checklistName][topicName] = topicData.questions;
            });
        });
        setTopicProgress(newTopicProgress);
        setSelectedAnswers(tempSelectedAnswers);
        setShowCommentBox(tempShowCommentBox);
        setComments(tempComments);
        setOrganizedData(tempOrganizedData);
       
      });
    }
  }, [auditContent]);
  
  const handleRegulationClick = async (questionID) => {
    // Show loading state
    setLoadingStates((prev) => ({ ...prev, [questionID]: true }));
  
    try {
      const response = await axios.get(`${apiUrl}/audit/get_regulation_content/${questionID}`);
      // Grab the string content
      const content = response.data.content; 
      console.log("Full response data:", response.data);
      console.log("Type of content in JS:", typeof content);
      console.log("Content value:", content);
  
      // Display in SweetAlert
      Swal.fire({
        title: "Regulations",
        html: `
        <pre style="
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          max-width: 100%;
          max-height: 400px;
          overflow-y: auto;
          font-family: Arial, sans-serif;
      ">
      ${content}
        </pre>
      `,
      
      
        width: "auto",
        showCloseButton: true,
      }).then(() => {
        // Once user closes the modal
        setLoadingStates((prev) => ({ ...prev, [questionID]: false }));
      });
  
    } catch (error) {
      console.error("Error fetching regulation content:", error);
  
      // Stop loading if there's an error
      setLoadingStates((prev) => ({ ...prev, [questionID]: false }));
  
      Swal.fire({
        title: "Error",
        text: "Failed to fetch regulation content",
        icon: "error",
      });
    }
  };
  
  
  
  const toggleTopic = (checklistName, topicIndex) => {
    setActiveTopicIndices((prevIndices) => {
      const currentActiveIndex = prevIndices[checklistName];
      const newIndices = {
        ...prevIndices,
        [checklistName]: currentActiveIndex === topicIndex ? null : topicIndex,
      };
      return newIndices;
    });
  };

  const fetchSuggestedRegulations = async (userInput) => {
    try {
        // Prepare the request body
        const requestBody = { userInputText: userInput };

        // Fetch suggested regulations based on the user input text
        const response = await fetch(`${apiUrl}/audit/get_reg_suggestions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error('Failed to fetch suggested regulations');
        }
  
        const data = await response.json();
        
        // Extract suggested regulations from the response data
        const suggestedRegulations = data.suggested_regulations || [];
        
        return { suggestedRegulations };
    } catch (error) {
        console.error('Error fetching suggested regulations:', error);
        throw error;
    }
};


const populateDropdown = (suggestedRegulations) => {
  const dropdown = document.getElementById('dropdownList');
  console.log('Suggested Regulations:', suggestedRegulations.title);
  // Clear existing options
  dropdown.innerHTML = '';

  // Populate dropdown with new options
  for (let i = 0; i < suggestedRegulations.length; i++) {
      const option = document.createElement('option');
      option.textContent = suggestedRegulations[i].title; // Display only the title
      option.value = JSON.stringify(suggestedRegulations[i]); // Store the full regulation object as JSON string
      dropdown.appendChild(option);
  }
};

const improveQuestionWithAI = async (userInput, erule) => {
  console.log('Improving Question with AI:', userInput, erule);
  try {
    // Call the endpoint to generate an improved question with AI
    const response = await axios.get(`${apiUrl}/audit/generate_improved_question`, {
      params: {
        erule: erule,
        user_input: userInput
      }
    });

    // Replace the user input with the improved question
    const improvedQuestion = response.data.question;
    console.log('Improved Question:', improvedQuestion);

    // Update the text area in the Swal popup with the improved question
    document.getElementById('userInput').value = improvedQuestion;
  } catch (error) {
    console.error('Error improving question with AI:', error);
    // Handle error if needed
  }
};

const handleAddQuestionClick = async (topicIndex, checklistIndex) => {
  console.log('Topic & Checklist', topicIndex, checklistIndex, auditId);

  try {
    // Show Swal popup to get user input
    const { value } = await Swal.fire({
      title: 'Write Your Own Question',
      html: `
        <textarea id="userInput" class="swal2-textarea" placeholder="Enter your question here..." style="resize: vertical; width: 46vw; height: 20vh;"></textarea>
        <div style="display: flex; align-items: center;">
          <button id="fetchRegulationsBtn" class="swal2-confirm swal2-styled" style="display: block; width: 40%; margin: 0 auto;">Suggest Regulations</button>
          <button id="improveQuestionBtn" class="swal2-confirm swal2-styled" style="display: block; width: 40%; margin: 0 auto;" disabled>Improve Question With AI</button>
          <span id="spinnerContainer"></span> <!-- Container for spinner -->
        </div>
        <div style="display: flex; align-items: center;">
          <p style="margin-right: 10px; font-weight: bold;">Select a Regulation:</p>
          <select id="dropdownList" class="swal2-select" style="width: 70%; border: 1px solid #ccc;"></select>
        </div>
      `,
      showCancelButton: true,
      width: "75vh",
      confirmButtonText: 'Add Question',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        // Return the user input value and selected erule
        const userInput = document.getElementById('userInput').value;
        const selectedRegulation = document.getElementById('dropdownList').value;
        const { erule } = JSON.parse(selectedRegulation);

        return { userInput, erule };
      },
      didOpen: () => {
        // Attach event listener to the fetch regulations button
        document.getElementById('fetchRegulationsBtn').addEventListener('click', async () => {
          // Get the user input from the text area
          const userInput = document.getElementById('userInput').value;

          // Fetch suggested regulations based on the user input text
          const response = await fetchSuggestedRegulations(userInput);

          // Access the suggested regulations array from the response
          const suggestedRegulations = response.suggestedRegulations || [];

          // Populate dropdown with suggested regulations
          populateDropdown(suggestedRegulations);

          // Enable/disable improveQuestionBtn based on userInput and erule
          handleImproveQuestionButtonState();
        });

        // Attach event listener to the improve question button
        document.getElementById('improveQuestionBtn').addEventListener('click', async () => {
          const userInput = document.getElementById('userInput').value;
          const selectedRegulation = document.getElementById('dropdownList').value;
          const { erule } = JSON.parse(selectedRegulation);

          // Show spinner while processing
          const spinnerContainer = document.getElementById('spinnerContainer');
          spinnerContainer.innerHTML = ''; // Clear any previous spinner
          spinnerContainer.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
          
          try {
            // Call the function to improve the question using AI if erule and userInput are defined
            if (erule && userInput) {
              await improveQuestionWithAI(userInput, erule);
            }
          } catch (error) {
            console.error('Error improving question:', error);
          } finally {
            // Remove spinner after processing
            spinnerContainer.innerHTML = ''; // Clear the spinner
          }
        });

        // Enable/disable improveQuestionBtn based on userInput and erule
        handleImproveQuestionButtonState();
      }
    });

    // Handle the user input value if needed
    if (value) {
      // Proceed with adding the question
      const { userInput, erule } = value;
      console.log('User Input:', userInput);
      console.log('Selected Erule:', erule);
      console.log('Topic Index:', topicIndex);
      console.log('Checklist Index:', checklistIndex);

      // Add logic to send the user input, erule, and auditID to the endpoint
      await addQuestionToDB(userInput, erule,  topicIndex, checklistIndex);
    }
  } catch (error) {
    console.error('Error displaying Swal:', error);
    Swal.fire({
      title: 'Error',
      text: 'Failed to display popup',
      icon: 'error'
    });
  }
};


const handleImproveQuestionButtonState = () => {
  const userInput = document.getElementById('userInput').value;
  const selectedRegulation = document.getElementById('dropdownList').value;
  
  const improveQuestionBtn = document.getElementById('improveQuestionBtn');
  
  // Enable improveQuestionBtn only if userInput and selectedRegulation are defined
  if (userInput && selectedRegulation) {
    improveQuestionBtn.disabled = false;
  } else {
    improveQuestionBtn.disabled = true;
  }
};

  const handleSelectAnswer = async (auditContentId, answer, questionID) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const currentAnswer = prevSelectedAnswers[questionID];
      const newAnswer = currentAnswer === answer ? null : answer;
      const updatedAnswers = {
        ...prevSelectedAnswers,
        [questionID]: newAnswer,
      };
      // Send the updated or nullified answer to the server
      updateAnswerInDB(auditContentId, newAnswer);
      // Find the topicKey for the questionID and update progress
      const questionItem = auditContent.find((q) => q.id === auditContentId);
      if (questionItem) {
        updateTopicProgress(
          questionItem.checklist_name,
          questionItem.topic_name,
          updatedAnswers
        );
      }
      return updatedAnswers;
    });
  };

  const queryScoreForAnswer = async (answer) => {
    try {
      const response = await axios.post(
        `${apiUrl}/audit/query_score_for_answer`,
        {
          answer: answer,
        }
      );
      return response.data.score; // This will be the calculated score
    } catch (error) {
      console.error("Error querying score for answer", error);
      return null; // Handle the error case
    }
  };

  const addQuestionToDB = async (userInput, erule, topicIndex, checklistIndex) => {
    console.log("Adding question to DB:", userInput, checklistIndex);
    try {
        // Send the data to the endpoint to add the question
        const response = await axios.post(`${apiUrl}/audit/add_user_question`, {
            user_input: userInput,
            erule: erule,
            owner_id: auditId
        });

        // Extract the question ID from the response
        const questionID = response.data.ID;

        console.log("Data", topicIndex, checklistIndex, auditId, questionID, erule); 

        // Now, call the endpoint to add the user checklist detail
        await axios.post(`${apiUrl}/audit/add_user_checklist_detail`, {
          topicIndex: topicIndex,
          checklistIndex: checklistIndex,
          AuditId: auditId,
          QuestionId: questionID,
          erule: erule
      });

      // Reload the view
      fetchAuditContent();

      // Toggle the topic open again
      toggleTopicAfterRefresh(checklistIndex, topicIndex);
        
        // Handle success response if needed
    } catch (error) {
        console.error("Error adding question to DB:", error);
        // Handle error response if needed
    }
};

// Toggle topic function
const toggleTopicAfterRefresh = (checklistIndex, topicIndex) => {
    setActiveTopicIndices((prevIndices) => {
        const currentActiveIndex = prevIndices[checklistIndex];
        const newIndices = {
            ...prevIndices,
            [checklistIndex]: currentActiveIndex === topicIndex ? null : topicIndex,
        };
        return newIndices;
    });
};


const updateAuditStatusOnServer = async (auditId) => {
 
  try {
    await axios.post(`${apiUrl}/audit/update_audit_status/${auditId}`);
  } catch (error) {
    console.error("Error updating audit status:", error);
    // Handle error appropriately
  }
};

  const updateAnswerInDB = async (auditContentId, answer) => {
    console.log("Updating answer and score in DB", auditContentId, answer);
    try {
      // Query the score for the answer
      const score = await queryScoreForAnswer(answer);

      // Update the answer and score in the database
      const response = await axios.post(`${apiUrl}/audit/update_answer`, {
        id: auditContentId,
        answer: answer !== null ? answer : "",
        score: score !== null ? score : 0, // Send 0 if score is null
      });
      console.log("AuditID", auditId);
      updateAuditStatusOnServer(auditId)
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating answer and score", error);
    }
  };

  const updateTopicProgress = (checklistName, topicName, updatedAnswers) => {
    // Calculate the new progress for the specified topic
    const topicKey = `${checklistName}-${topicName}`;
    const topicQuestions = organizedData[checklistName][topicName];
    // Ensure that topicQuestions is defined and not empty
    if (!topicQuestions || topicQuestions.length === 0) {
      console.error(`No questions found for topic: ${topicKey}`);
      return;
    }
    const answeredCount = topicQuestions.filter(
      (q) => updatedAnswers[q.questionID]
    ).length;
    // Update the topicProgress state
    setTopicProgress((prevProgress) => ({
      ...prevProgress,
      [topicKey]: { total: topicQuestions.length, answered: answeredCount },
    }));
  };
  const handleCommentChange = debounce(async (auditContentId, comment) => {
    try {
      const response = await axios.post(`${apiUrl}/audit/update_comment`, {
        id: auditContentId,
        comment,
      });
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating comment", error);
    }
  }, 500); // Waits 500ms after the last keystroke to send the request
 
  const getButtonBackgroundColor = (answer, questionID) => {
    // Use questionID to check the selected answer for the specific question
    const selectedAnswer = selectedAnswers[questionID];
    switch (selectedAnswer) {
      case "Exceeding":
        return "bg-green-500"; // Dark green for "Exceed"
      case "Compliant":
        return "bg-green-300"; // Light green for "Compliant"
      case "Partially Compliant":
        return "bg-yellow-300"; // Yellow for "Partially Compliant"
      case "Non Compliant":
        return "bg-orange-500"; // Orange for "Non Compliant"
      case "Unsafe":
        return "bg-red-500"; // Red for "Dangerous"
      case "N/A":
        return "bg-blue-300"; // Gray for "N/A"
      default:
        return "bg-gray-200"; // Default color
    }
  };
  const handleTextareaInput = (e) => {
    e.target.style.height = "auto"; // Reset height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set to scroll height
  };
  const handleAttachMedia = async (auditHeaderId, auditContentId) => {
    let response;
    const fileInput = document.getElementById(`file-input-${auditContentId}`);
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("file", file);

      try {
        response = await axios.put(
          `${apiUrl}/audit/upload_media/${auditHeaderId}/${auditContentId}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(response.data.message);
      } catch (error) {
        console.error("Error uploading media", error);
        return; // Exit the function if there's an error
      }
    } else {
      console.error("No file selected");
      return; // Exit the function if no file is selected
    }

    if (response && response.status === 200) {
      const updatedAttachments = await fetchAttachments(auditContentId);
      updateAttachmentsInState(auditContentId, updatedAttachments);
    }
  };

  const updateAttachmentsInState = (auditContentId, updatedAttachments) => {
    setOrganizedData((prevData) => {
      const newData = { ...prevData };
      Object.keys(newData).forEach((checklistName) => {
        Object.keys(newData[checklistName]).forEach((topicName) => {
          newData[checklistName][topicName].questions.forEach((question) => {
            if (question.id === auditContentId) {
              question.attachments = updatedAttachments;
            }
          });
        });
      });
      return newData;
    });
  };
  const fetchPresignedUrl = async (attachment) => {
    const orgId = attachment.s3_key.split("/")[0]; // Extract org_id from s3_key
    const filePath = attachment.s3_key.substring(orgId.length + 1); // Get the rest of the s3_key as file path
    try {
      const response = await axios.get(
        `${apiUrl}/organisations/retrieve_file/${orgId}/${filePath}`
      );
      return response.data.url;
    } catch (error) {
      console.error("Error fetching presigned URL", error);
      return null;
    }
  };

  const getAttachmentDisplay = (filename, url) => {
    if (!filename || !url) {
      return "Attachment not available";
    }

    const fileExtension = filename.split(".").pop().toLowerCase();

    if (["png", "jpg", "jpeg"].includes(fileExtension)) {
      // Show clickable thumbnail for images
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">
                <img src="${url}" alt="${filename}" style="width:100px;height:auto;">
              </a>`;
    } else if (fileExtension === "pdf") {
      // Show clickable PDF icon for PDF files
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">
                <img src="/file-pdf-solid.svg" alt="PDF" style="width:34px;height:auto;"> ${filename}
              </a>`;
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      // Show clickable Word icon for DOC files
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">
                <img src="/file-word-solid.svg" alt="Word" style="width:34px;height:auto;"> ${filename}
              </a>`;
    } else if (["mp4", "avi"].includes(fileExtension)) {
      // Show clickable video icon for video files
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">
                <img src="/file-video-solid.svg" alt="Video" style="width:34px;height:auto;"> ${filename}
              </a>`;
    } else {
      // Default case for other file types
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${filename}</a>`;
    }
  };

  const handleShowAttachments = async (attachments) => {
    if (!attachments || attachments.length === 0) {
      Swal.fire({
        title: "No Attachments",
        text: "There are no attachments for this question.",
        icon: "info",
      });
      return;
    }
    const attachmentDisplays = await Promise.all(
      attachments.map(async (attachment) => {
        if (!attachment || !attachment.s3_key) {
          return `<li>Attachment not available</li>`;
        }
        const filename = attachment.s3_key.split("/").pop();
        const url = await fetchPresignedUrl(attachment);
        if (!url) {
          return `<li>${filename} (Error retrieving file)</li>`;
        }
        return `<li>${getAttachmentDisplay(filename, url)}</li>`;
      })
    );
    Swal.fire({
      title: "Attachments",
      html: `<ul>${attachmentDisplays.join("")}</ul>`,
      // other Swal options
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-row h-screen">
        <div className="flex flex-col w-full h-screen">
          {Object.entries(organizedData).map(
            ([checklistName, topics], checklistIndex) => (
              <div key={checklistIndex} className="p-4 border round">
                <h2 className="mb-2 text-2xl font-bold">{checklistName}</h2>
                {Object.entries(topics).map(
                  ([topicName, questions], topicIndex) => {
                    const progressKey = `${checklistName}-${topicName}`;
                    const progress = topicProgress[progressKey] || {
                      answered: 0,
                      total: 0,
                    };
                    const unsafe = isTopicUnsafe(questions);
                    const exceed = isTopicExceed(questions);
                    return (
                      <div key={topicIndex} className="p-4 border rounded"
                      onDragEnter={(e) => handleDragEnter(e, { checklistIndex, topicIndex, questionIndex })}
                     
                      onDragEnd={handleDragEnd}
                    >
                        <div className="flex justify-between items-center">
                          <h3
                            className="mb-2 text-xl cursor-pointer"
                            onClick={() =>
                              toggleTopic(checklistName, topicIndex)
                            }
                          >
                            {topicName}
                          </h3>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginBottom: "10px"
                              
                            }}
                          >
                            {unsafe && (
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                color="red"
                                title="Unsafe conditions identified"
                                size="2x"
                              />
                            )}
                            {exceed && (
                              <FontAwesomeIcon
                                icon={faTrophy}
                                color="#E0A80F"
                                title="Exceeded Expectations"
                                size="2x"
                              />
                            )}
                            <CircularProgress
                              total={progress.total || 0}
                              answered={progress.answered || 0}
                            />
                          </div>
                        </div>
                        {activeTopicIndices[checklistName] === topicIndex && (
                          <ul className="space-y-4">
                            {questions.map((questionItem) => (
                              <StyledListItemLiveAudit
                              key={questionItem.questionID}
                              className={`p-2 border rounded text-md bg-gray-100 ${dropTarget && dropTarget.checklistIndex === checklistIndex && dropTarget.topicIndex === topicIndex ? "bg-gray-200" : ""}`}
                              draggable
                              onDragStart={(e) => handleDragStart(e, { checklistIndex, topicIndex, questionIndex: questionItem.q_list_order, questionID: questionItem.questionID })}
                              onDragEnd={handleDragEnd}
                              onDragEnter={(e) => handleDragEnter(e, { checklistIndex, topicIndex, questionIndex: questionItem.q_list_order })}


                          >
                                <strong>
                                  Question: {questionItem.questionText}
                                </strong>
                                <div>
                                  {[
                                    "Exceeding",
                                    "Compliant",
                                    "Partially Compliant",
                                    "Non Compliant",
                                    "Unsafe",
                                    "N/A",
                                  ].map((answer) => (
                                    <button
                                      key={answer}
                                      className={`p-2 rounded m-2 ${
                                        selectedAnswers[
                                          questionItem.questionID
                                        ] === answer
                                          ? getButtonBackgroundColor(
                                              answer,
                                              questionItem.questionID
                                            ) + " text-gray-800"
                                          : "bg-gray-200 text-black"
                                      }`}
                                      onClick={() =>
                                        handleSelectAnswer(
                                          questionItem.id,
                                          answer,
                                          questionItem.questionID
                                        )
                                      }
                                    >
                                      {answer}
                                    </button>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {!showCommentBox[questionItem.id] ? ( // Use questionItem.id or appropriate identifier
                                    <button
                                      className="text-black"
                                      onClick={() =>
                                        setShowCommentBox((prev) => ({
                                          ...prev,
                                          [questionItem.id]: true,
                                        }))
                                      }
                                    >
                                      <FontAwesomeIcon icon={faComment} />
                                    </button>
                                  ) : (
                                    <AutoResizeTextarea
                                      id={`comment-${questionItem.id}`}
                                      placeholder="Comment"
                                      defaultValue={comments[questionItem.id]} // Use the correct comment from the state
                                      onChange={(e) => {
                                        handleCommentChange(
                                          questionItem.id,
                                          e.target.value
                                        );
                                        handleTextareaInput(e);
                                      }}
                                    />
                                  )}
                                  {/* File Upload and Attachment Icons */}
                                  <input
                                    type="file"
                                    id={`file-input-${questionItem.id}`}
                                    style={{ display: "none" }}
                                    onChange={() =>
                                      handleAttachMedia(
                                        questionItem.auditHeaderId,
                                        questionItem.id
                                      )
                                    }
                                  />
                                  <button
                                    className="text-black"
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          `file-input-${questionItem.id}`
                                        )
                                        .click()
                                    }
                                  >
                                    <FontAwesomeIcon icon={faFileUpload} />
                                  </button>
                                  {questionItem.attachments &&
                                    questionItem.attachments.length > 0 && (
                                      <button
                                        className="text-black"
                                        onClick={() =>
                                          handleShowAttachments(
                                            questionItem.attachments
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faPaperclip}
                                          color="black"
                                        />
                                      </button>
                                    )}
                                </div>
                                <div>
                                  {loadingStates[questionItem.questionID] ? (
                                    <span className="inline-block ml-2">
                                      <BounceLoader
                                        color="#E0A80F"
                                        loading={true}
                                        size={20}
                                      />
                                    </span>
                                  ) : (
                                    <small
                                      className="text-gray-500 cursor-pointer"
                                      onClick={() =>
                                        handleRegulationClick(
                                          
                                          questionItem.questionID
                                        )
                                      }
                                    >
                                      Regulation
                                    </small>
                                  )}
                                </div>
                              </StyledListItemLiveAudit>
                            ))}
                             {/* Plus button to add another question */}
                             <div className="flex justify-center items-center">
                             <button
                              className="p-2 mt-2"
                              style={{ fontSize: '20px' }} // Make the icon bigger
                              onClick={(e) => handleAddQuestionClick(topicIndex, checklistIndex)}
                            >
    <FontAwesomeIcon icon={faSquarePlus} size="2xl" />
  </button>
</div>


                          </ul>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            )
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};
export default AuditContentLive;
